<template>
  <div v-if="sellCardsData" class="">
    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("Cards.serialNumber") }}</th>
            <th>{{ $t("Cards.salePrice") }}</th>
            <th>{{ $t("info") }}</th>
            <th>{{ $t("actionsData.modelName") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(sellCard, index) in sellCardsData" :key="index">
            <td>{{ ++index }}</td>
            <td>
              {{ isDataExist(sellCard.cardData.cardSerialNumber) }}
            </td>
            <td>
              {{ isDataExist(sellCard.cardSalePriceView) }}
            </td>
            <td>
              <button
                v-b-modal.SellCardInfo
                class="btn p-0"
                :title="$t('info')"
                @click="setSellCardData(sellCard)"
              >
                <img src="@/assets/images/info.svg" class="icon-lg" />
              </button>
            </td>
            <td>
              <button
                v-b-modal.ActionsData
                class="btn p-0"
                :title="$t('actionsData.modelName')"
                @click="setSellCardData(sellCard)"
              >
                <img src="@/assets/images/actions-data.svg" class="icon-lg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { isDataExist } from "./../../utils/functions";

export default {
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["sellCardsData"],
  methods: {
    setSellCardData(card) {
      this.$emit("setSellCardData", card);
    },
    isDataExist,
  },
};
</script>
