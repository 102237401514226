<template>
  <b-modal id="SellCardInfo" scrollable size="lg" centered hide-footer>
    <template #modal-title>
      <h3>
        <img src="@/assets/images/sellCards.svg" class="icon-lg" />
        {{ $t("SellCards.data") }}
      </h3>
    </template>
    <div class="">
      <div class="my-card">
        <div class="row">
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="sellCard.cardSalePriceView"
            :title="$t('Cards.salePrice')"
            :imgName="'money.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="
              formateDateTimeLang(
                sellCard.cardData.saleDate,
                sellCard.cardData.saleTime
              )
            "
            :title="$t('Cards.saleDateTime')"
            :imgName="'dateAndTime.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6'"
            :value="sellCard.sellCardDescriptionAr"
            :title="$t('SellCards.descriptionAr')"
            :imgName="'description.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="sellCard.sellCardDescriptionEn"
            :title="$t('SellCards.descriptionEn')"
            :imgName="'description.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6'"
            :value="sellCard.sellCardNotes"
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6'"
            :value="sellCard.cardData.durationActivation"
            :title="$t('Cards.durationActivation')"
            :imgName="'dateAndTime.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6'"
            :value="
              formateDateTimeLang(
                sellCard.cardData.startActivationDate,
                sellCard.cardData.startActivationTime
              )
            "
            :title="$t('Cards.startActivationDateTime')"
            :imgName="'dateAndTime.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="
              formateDateTimeLang(
                sellCard.cardData.endActivationDate,
                sellCard.cardData.endActivationTime
              )
            "
            :title="$t('Cards.endActivationDateTime')"
            :imgName="'dateAndTime.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6'"
            :value="sellCard.cardData.countEnquiryQrCodeVCD"
            :title="$t('Cards.countEnquiryQrCodeVCD')"
            :imgName="'number.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="sellCard.cardData.countQrCodeSessionsPurchasestotal"
            :title="$t('Cards.countQrCodeSessionsPurchasestotal')"
            :imgName="'number.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6'"
            :value="sellCard.cardData.countQrCodeSessionsPurchasesInArchive"
            :title="$t('Cards.countQrCodeSessionsPurchasesInArchive')"
            :imgName="'number.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="sellCard.cardData.countQrCodeSessionsPurchasesNotArchive"
            :title="$t('Cards.countQrCodeSessionsPurchasesNotArchive')"
            :imgName="'number.svg'"
          />
        </div>
      </div>

      <div class="my-card">
        <span class="my-card-title">{{ $t("Cards.userOwner") }}</span>

        <div class="row">
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="sellCard.cardData.userOwnerData.userNameCurrent"
            :title="$t('users.name')"
            :imgName="'users.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="sellCard.cardData.userOwnerData.fullCode"
            :title="$t('users.code')"
            :imgName="'code.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6 phone-number'"
            :value="sellCard.cardData.userOwnerData.userPhoneWithCC"
            :title="$t('users.phone')"
            :imgName="'phone.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="sellCard.cardData.userOwnerData.userEmail"
            :title="$t('users.email')"
            :imgName="'email.svg'"
          />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "./../../components/general/DataLabelGroup.vue";
import { formateDateTimeLang } from "./../../utils/functions";

export default {
  components: {
    DataLabelGroup,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["sellCard"],
  methods: {
    formateDateTimeLang(date, time) {
      return formateDateTimeLang(date, time, this.language);
    },
  },
};
</script>

<style scoped lang="scss"></style>
