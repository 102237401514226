var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"SellCardInfo","scrollable":"","size":"lg","centered":"","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h3',[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/sellCards.svg")}}),_vm._v(" "+_vm._s(_vm.$t("SellCards.data"))+" ")])]},proxy:true}])},[_c('div',{},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.sellCard.cardSalePriceView,"title":_vm.$t('Cards.salePrice'),"imgName":'money.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.formateDateTimeLang(
              _vm.sellCard.cardData.saleDate,
              _vm.sellCard.cardData.saleTime
            ),"title":_vm.$t('Cards.saleDateTime'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.sellCard.sellCardDescriptionAr,"title":_vm.$t('SellCards.descriptionAr'),"imgName":'description.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.sellCard.sellCardDescriptionEn,"title":_vm.$t('SellCards.descriptionEn'),"imgName":'description.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.sellCard.sellCardNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.sellCard.cardData.durationActivation,"title":_vm.$t('Cards.durationActivation'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.formateDateTimeLang(
              _vm.sellCard.cardData.startActivationDate,
              _vm.sellCard.cardData.startActivationTime
            ),"title":_vm.$t('Cards.startActivationDateTime'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.formateDateTimeLang(
              _vm.sellCard.cardData.endActivationDate,
              _vm.sellCard.cardData.endActivationTime
            ),"title":_vm.$t('Cards.endActivationDateTime'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.sellCard.cardData.countEnquiryQrCodeVCD,"title":_vm.$t('Cards.countEnquiryQrCodeVCD'),"imgName":'number.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.sellCard.cardData.countQrCodeSessionsPurchasestotal,"title":_vm.$t('Cards.countQrCodeSessionsPurchasestotal'),"imgName":'number.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.sellCard.cardData.countQrCodeSessionsPurchasesInArchive,"title":_vm.$t('Cards.countQrCodeSessionsPurchasesInArchive'),"imgName":'number.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.sellCard.cardData.countQrCodeSessionsPurchasesNotArchive,"title":_vm.$t('Cards.countQrCodeSessionsPurchasesNotArchive'),"imgName":'number.svg'}})],1)]),_c('div',{staticClass:"my-card"},[_c('span',{staticClass:"my-card-title"},[_vm._v(_vm._s(_vm.$t("Cards.userOwner")))]),_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.sellCard.cardData.userOwnerData.userNameCurrent,"title":_vm.$t('users.name'),"imgName":'users.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.sellCard.cardData.userOwnerData.fullCode,"title":_vm.$t('users.code'),"imgName":'code.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6 phone-number',"value":_vm.sellCard.cardData.userOwnerData.userPhoneWithCC,"title":_vm.$t('users.phone'),"imgName":'phone.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.sellCard.cardData.userOwnerData.userEmail,"title":_vm.$t('users.email'),"imgName":'email.svg'}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }