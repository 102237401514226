export default class UserCreatedData {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.userToken = "";
    this.userNameCurrent = "";
    this.userFullCode = "";
    this.userPhoneWithCC = "";
    this.userImagePath = "";
    this.userTypeToken = "";
    this.userTypeNameCurrent = "";
  }
  fillData(data) {
    this.userToken = data.userToken || "";
    this.userNameCurrent = data.userNameCurrent || "";
    this.userFullCode = data.userFullCode || "";
    this.userPhoneWithCC = data.userPhoneWithCC || "";
    this.userImagePath = data.userImagePath || "";
    this.userTypeToken = data.userTypeToken || "";
    this.userTypeNameCurrent = data.userTypeNameCurrent || "";
  }
}
