import UserCreatedData from "./UserCreatedData";

export default class BaseEntityModel {
  constructor() {
    this.setInitialValueBaseEntityModel();
  }
  setInitialValueBaseEntityModel() {
    this.dailyCode = "";
    this.fullCode = "";
    this.createdByUserToken = "";
    this.userCreatedData = new UserCreatedData();
    this.lastUpdatedByUserToken = "";
    this.userLastUpdatedData = new UserCreatedData();
    this.establishmentToken = "";
    this.timeZoneName = "";
    this.creationDateTime = "";
    this.creationDate = "";
    this.creationTime = "";
    this.lastUpdateDateTime = "";
    this.lastUpdateDate = "";
    this.lastUpdateTime = "";
  }
  fillDataBaseEntityModelBaseEntityModel(data) {
    if (data) {
      this.dailyCode = data.dailyCode || "";
      this.fullCode = data.fullCode || "";
      this.createdByUserToken = data.createdByUserToken || "";
      this.userCreatedData.fillData(
        data.userCreatedData || new UserCreatedData()
      );
      this.lastUpdatedByUserToken = data.lastUpdatedByUserToken || "";
      this.userLastUpdatedData.fillData(
        data.userLastUpdatedData || new UserCreatedData()
      );
      this.establishmentToken = data.establishmentToken || "";
      this.timeZoneName = data.timeZoneName || "";
      this.creationDateTime = data.creationDateTime || "";
      this.creationDate = data.creationDate || "";
      this.creationTime = data.creationTime || "";
      this.lastUpdateDateTime = data.lastUpdateDateTime || "";
      this.lastUpdateDate = data.lastUpdateDate || "";
      this.lastUpdateTime = data.lastUpdateTime || "";
    }
  }
}
