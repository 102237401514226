import PaginationData from "./../../models/general/PaginationData";
import SellCard from "./SellCard";
import SellCardsFilter from "./SellCardsFilter";

export default class SellCards {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.status = 0;
    this.msg = "";
    this.pagination = new PaginationData();
    this.sellCardsData = [];
    this.sellCard = new SellCard();
    this.filterData = new SellCardsFilter();
  }
  fillData(data) {
    this.status = data.status;
    this.msg = data.msg;
    this.pagination.fillData(data.pagination);
    this.sellCardsData = data.sellCardsData;
  }
}
