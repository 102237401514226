<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div v-if="sellCards.sellCardsData">
      <SellCardsTable
        v-on:setSellCardData="sellCards.sellCard.fillData($event)"
        :sellCardsData="sellCards.sellCardsData"
      />

      <ActionsData :actionsData="sellCards.sellCard" />
      <SellCardInfo :sellCard="sellCards.sellCard" />
      <SellCardDelete
        :sellCard="sellCards.sellCard"
        v-on:refresh="getAllSellCards()"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="sellCards.pagination"
        v-on:changePage="changePage"
      />
    </div>

    <SellCardFloatBtns
      :theFilterData="sellCards.filterData"
      v-on:search="search($event)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import createToastMixin from "./../../utils/create-toast-mixin";
import PreLoader from "./../../components/general/PreLoader.vue";
import ExceptionWithImg from "./../../components/general/ExceptionWithImg.vue";
import { STATUS } from "./../../utils/constants";
import ActionsData from "./../../components/general/ActionsData.vue";
import SellCardsTable from "./../../components/sellCards/SellCardsTable.vue";
import SellCardInfo from "./../../components/sellCards/SellCardInfo.vue";
import SellCardDelete from "./../../components/sellCards/SellCardDelete.vue";
import SellCardFloatBtns from "./../../components/sellCards/SellCardFloatBtns.vue";
import Pagination from "./../../components/general/Pagination.vue";
import SellCards from "./../../models/sellCards/SellCards";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    ActionsData,
    SellCardsTable,
    SellCardInfo,
    SellCardDelete,
    SellCardFloatBtns,
    Pagination,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken"]),
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      sellCards: new SellCards(),
    };
  },
  methods: {
    changePage(page) {
      this.sellCards.pagination.selfPage = page;
      this.getAllSellCards();
    },
    search(data) {
      this.sellCards.filterData.fillData(data);
      this.sellCards.sellCardsData = [];
      this.getAllSellCards();
    },
    async getAllSellCards() {
      this.isLoading = true;
      try {
        const response = await this.sellCards.sellCard.getAllSellCards(
          this.language,
          this.userAuthorizeToken,
          this.sellCards.pagination,
          this.sellCards.filterData
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.sellCards.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.sellCards.sellCardsData = null;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.sellCards.sellCardsData = null;
          this.$store.dispatch("logoutUser");
        } else {
          this.sellCards.sellCardsData = null;
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.sellCards.sellCardsData = null;
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getAllSellCards();
  },
};
</script>
